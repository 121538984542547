<template>
	<swiper class="pt-1" id="menu-horizon" :options="swiperOption">
		<template v-if="action">
			<swiper-slide v-for="tab in tabsComputed" :key="tab.label">
				<a @click.stop.prevent="action(tab)" href="" :class="{ active: tab.active }">{{ $t(tab.label) }}</a>
			</swiper-slide>
		</template>
		<template v-else>
			<swiper-slide v-for="tab in tabsComputed" :key="tab.label">
				<b-dropdown
					v-show="tab.dropdown"
				>
        			<template slot="button-content" >
						{{ $t(tab.label) }}
					</template>

                    <b-dropdown-item v-for="itemDrop in tab.dropdown" :key="itemDrop.label">
						<b-dropdown
							v-show="itemDrop.dropdown"
						>
		        			<template slot="button-content" >
								{{ $t(itemDrop.label) }}
							</template>

							<b-dropdown-item v-for="itemSubDrop in itemDrop.dropdown" :key="itemSubDrop.label">
								<router-link :to="{ name: itemSubDrop.href, params: itemDrop.params }" title="" replace>{{ $t(itemSubDrop.label) }}</router-link>
							 </b-dropdown-item>
						</b-dropdown>

						<router-link v-show="!itemDrop.dropdown" :to="{ name: itemDrop.href, params: tab.params }" title="" replace>{{ $t(itemDrop.label) }}</router-link>
                    </b-dropdown-item>

				</b-dropdown>
				<router-link v-show="!tab.dropdown" :to="{ name: tab.href, params: tab.params }" title="" :class="{ active: tab.active }" replace>{{ $t(tab.label) }}</router-link>
			</swiper-slide>
		</template>
	</swiper>
</template>

<script type="text/javascript">
	import 'swiper/dist/css/swiper.css'
	import { swiper, swiperSlide } from 'vue-awesome-swiper'

	export default {
        props: {
            action: { type: Function, default: null },
            tabs: { type : Array, default: () => [] }
        },
		data () {
			return {
                local_tabs: [],
				swiperOption: {
					slidesPerView: 'auto',
					spaceBetween: 0
				}
			}
        },
        created() {
            this.preinit_component()
        },
		mounted() {
            this.set_active_tab()
		},
		methods: {
            preinit_component() {
                this.local_tabs = this.tabs
            },
			set_active_tab() {
				if(!this.local_tabs || this.local_tabs.length === 0)
					return false

				if(!this.local_tabs[0].href)
					return false

                const old_tab = this.local_tabs.find(tab => tab.active === true)
                if(old_tab) old_tab.active = false

                const route_name = this.$route.name
                let actual_tab = this.local_tabs.find(tab => tab.href == route_name)

                // on cherche dans les enfants de l'onglet
                // permet de mettre en actif l'onglet lorsqu'on est dans un composant du dropdown de cet onglet
                // inutile pour le moment mais on sait jamais
                // if(!actual_tab) {
                // 	const length_matched = this.$route.matched.length
                // 	const parent_route = this.$route.matched[length_matched - 2]
                // 	actual_tab = this.local_tabs.find(tab => tab.href == parent_route.name)
                // }
                if(actual_tab) actual_tab.active = true
			}
		},
		computed: {
			tabsComputed() {
                if(!this.local_tabs) return []
                
                let tabs =  []

                this.local_tabs.forEach(tab => {
                    if(!tab.params) tab.params = {}
                    if(!tab.hidden) tabs.push(tab)
                })

                return tabs
            }
		},
		watch: {
            tabs: {
				handler(val) {
					this.local_tabs = val
				},
				deep: true
            },
			'$route' (val) {
                this.set_active_tab()
			}
		},
        components: {
            swiper,
            swiperSlide
        }
	}
</script>
